import React from "react";
import Modal from "react-bootstrap/Modal";

function ErrorModal(props) {
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Error!</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
    </Modal>
  );
}

export default ErrorModal;
